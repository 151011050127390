.header {
  padding: 25px 20px;
}
.content {
  background-color: #dfe5ee;
  overflow-y: hidden;
  min-height: 100vh;
}

.text-light{
  font-weight: 300;
  padding-left: 6px;
}
.content-container {
  margin: auto;
  width: 100%;
  max-width: 500px;
  padding: 35px;
}
@media screen and (min-width: 676px){
  .content-container {
    padding-top: 10%;
  }
}
@media screen and (min-width: 1400px){
  .content-container {
    padding-top: 5%;
  }
}