.success-page {
    color: #000000;
    letter-spacing: normal;
    text-align: left;
}
.success-page .title {
    font-family: "gk-light";
    font-size: 25px;
    line-height: normal;
    font-weight: 300;
    margin: 0;
}
.success-page .date {
    font-family: "gk-light";
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    margin-bottom: 15px;
}
.success-page .subtitle {
    font-size: 18px;
    line-height: 20px;
    font-family: "gk-light";
    font-weight: 300;
    letter-spacing: normal;
    margin-bottom: 15px;
}
.success-page p {
    font-size: 14px;
    line-height: 20px;
}
.success-page p.catalan {
    font-family: "gk-medium";
    font-weight: 400;
    margin-bottom: 30px;
}
.success-page p.catalan strong {
    font-family: "gk-semibold";
    font-weight: 600;
}
.success-page p.espanol {
    font-family: "gk-light";
    font-weight: 300;
}
.success-page p.espanol strong {
    font-family: "gk-medium";
    font-weight: 400;
}
.success-page .steps {
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    padding-top: 20px;
    cursor: pointer;
}
.success-page .steps:nth-last-child(2) {
    border-bottom: none;
    padding-bottom: 60px;
}
.success-page .steps .title {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
}
.success-page .steps .title .img-content {
    position: relative;
    width: 151px;
    height: auto;
    flex-basis: 100%;
}
.success-page .steps .title .img-content img {
    width: 100%;
    height: auto;
}
.success-page .steps .title .img-content::after {
    content: '';
    background: url(./img/muestra-gratis.png) no-repeat center;
    width: 63px;
    height: 63px;
    background-size: cover;
    position: absolute;
    right: -44px;
    top: 10px;
}
.success-page .steps .name-product {
    font-family: "gk-medium";
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
    padding-left: 15px;
}
.success-page .steps .info .catalan {
    font-family: "gk-medium";
    font-weight: 400;
    
}
.success-page .steps .info {
    font-family: "gk-light";
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    
}
.success-page .product-steps {
    cursor: pointer;
}
.success-page .product-steps .img-content img{
    width: 70px;
    height: 70px;
}
.success-page .product-steps {
    display: flex;
    justify-content: space-between; 
    padding-top: 20px;
}
.success-page .product-steps .img-content {
    text-decoration: none;
    color: black;
    font-family: "gk-medium";
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
}
.success-page .product-steps .img-content:hover {
    text-decoration: none;
}

.success-page .divisor {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    border-bottom: 1px solid white;
    padding-top: 20px;
}

.success-page .button-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 65px;
    left: 0;
    background-color: white;
    margin-top: 40px;
}
.success-page .button-download {
    height: 45px;
    width: 280px;
    border: 1px solid black;
    background-color: #000000;
    color: white;
    margin: auto;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

}
.success-page .button-download:hover {
    background-color: white;
    color: black;

}
.success-page .button-download .catalan {
    font-family: "gk-semibold";
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 1px;
}
.success-page .button-download .espanol {
    font-family: "gk-medium";
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1px;

}