.image-container {
  width: 100%;
}
.image-container img {
  width: 100%;
}
.primary-btn {
  background-color: #000000;
  padding: 10px;
  text-align: center;
}
.home-container a {
  color: #ffffff;
  font-family: "gk-medium";
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}
.primary-btn span {
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.title-container {
  margin-bottom: 15px;
}
.title-container h1 {
  color: #000000;
  font-family: "gk-semibold";
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  text-align: left;
  margin: 0;
  margin-bottom: 7px;
}
.title-container p {
  color: #000000;
  font-family: "gk-light";
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: normal;
  line-height: 18px;
  text-align: left;
  margin: 0;
}
.text-container p {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  font-family: "gk-semibold";
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-bottom: 4px;
}
.text-container p:last-child {
  font-family: "gk-light";
  font-weight: 300;
  font-style: italic;
  letter-spacing: normal;
  line-height: normal;
}
