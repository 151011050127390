.questionnaire-container .question p {
  color: #000000;
  font-family: "gk-light";
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin: 0;
}
.questionnaire-container .question p.catalan {
  font-size: 25px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  margin-bottom: 5px;
}
.empty-bar {
  height: 3px;
  border-radius: 3px;
  background-color: #ffffff;
}
.filled-bar {
  height: 3px;
  border-radius: 3px;
  background-color: #000000;
}
.answers {
  margin-top: 10px;
}
.answers > div {
  border-radius: 2px;
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 10px 15px;
  cursor: pointer;
}
.answers > div .letter {
  padding: 5px 8px;
  border-radius: 2px;
  background-color: #efefef;
  color: #000000;
  font-family: "gk-medium";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
  text-transform: uppercase;
  margin-right: 15px;
}
.answers > div {
  color: #000000;
  font-family: "gk-medium";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: left;
}
.answers > div span:not(.letter) {
  font-family: "gk-light";
  font-weight: 300;
  font-style: italic;
  letter-spacing: normal;
  line-height: normal;
}
.answers > div.selected {
  border-radius: 2px;
  border: 1px solid #000000;
  position: relative;
}
.answers > div.selected .letter {
  color: #ffffff;
  background-color: #000000;
}
.answers > div.selected::after {
  content: "";
  width: 12px;
  height: 5px;
  transform: rotate(-49deg);
  display: inline-block;
  right: 15px;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  position: absolute;
  top: 16px;
}
.actions .secondary,
.actions a {
  color: #000000;
  font-family: "gk-semibold";
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.actions .secondary::before,
.actions a::before {
  content: "";
  width: 5px;
  height: 5px;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  transform: rotate(-48deg);
  margin-right: 5px;
  display: inline-block;
}
.actions .secondary span,
.actions a span {
  font-family: "gk-medium";
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.actions .primary {
  color: #000000;
  font-family: "gk-semibold";
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.actions .primary::after {
  content: "";
  width: 5px;
  height: 5px;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  transform: rotate(-228deg);
  margin-right: 5px;
  display: inline-block;
}
.actions .primary {
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  font-family: "gk-medium"
}
.actions .primary span {
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  font-family: "gk-semibold";
}
.actions .primary.form {
  color: #ffffff;
  font-family: "gk-medium";
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  background-color: #000000;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.actions .primary.form span {
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.actions .primary.form::after {
  content: "";
  width: 5px;
  height: 5px;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: rotate(-228deg);
  margin-right: 5px;
  display: inline-block;
}
.actions {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
}
.questionnaire-container {
  min-height: 90vh;
}
.percent {
  color: #000000;
  font-family: "gk-semibold";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 5px;
}
.progress-bar {
  margin: 30px 20px;
}
.error-box {
  background-color: #ffe7e7;
  padding: 5px;
}
.error-box p {
  color: #000000;
  font-family: "gk-medium";
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 15px;
  text-align: center;
  margin-bottom: 5px;
}
.error-box p:last-child {
  font-family: "gk-light";
  font-weight: 300;
  font-style: italic;
}
